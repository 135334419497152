import { API_BASE } from './constants';

const coordsCache = {};

/**
 * Fetch stops in a given radius around lat/lng position.
 *
 * parkObjInfo=1&parkObjInfoRealtime=1
 *
 * @name fetchParkObjects
 * @function
 * @param {double|string} lat1 The latitude value of the bottom left corner.
 * @param {double|string} lng1 The longitude value of the bottom left corner.
 * @param {double|string} lat2 The latitude value of the top right corner.
 * @param {double|string} lng2 The longitude value of the top right corner.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object[]} Array of stops around the given position.
 */
export default async (lat1, lng1, lat2, lng2, fetchOptions = {}) => {
	const cacheId = `${lat1.toFixed(5)}|${lng1.toFixed(5)}|${lat2.toFixed(5)}|${lng2.toFixed(5)}`;
	if (typeof coordsCache[cacheId] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		const params = [
			'outputFormat=JSON',
			'coordOutputFormat=WGS84[DD.DDDDD]',
			'hideBannerInfo=1',
			'parkingSearchMethod=BB',
			'parkingBB=1',
			`parkingBBLU=${lng1}:${lat2}:WGS84[DD.DDDDD]`,
			`parkingBBRL=${lng2}:${lat1}:WGS84[DD.DDDDD]`,
			'parkingObjectType=15',
			`parkObjInfo=1`,
		];
		coordsCache[cacheId] = fetch(
			`${API_BASE}XSLT_COORD_REQUEST?${params.join('&')}`,
			fetchOptions,
		)
			.then(response => response.json())
			.then(json => json.pins);
	}
	return coordsCache[cacheId];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
