import { API_FREIZEITTIPPS_BASE } from './constants';

/**
 * Fetch leisure time tips around the given position
 *
 * @name fetchTipps
 * @function
 * @param {double|string} lat The latitude value of the position.
 * @param {double|string} lng The longitude value of the position.
 * @param {number} [page=0] Optional parameter for pagination.
 * @param {number} [limit=50] Limit of the leisure time tips that should be retrieved.
 * @param {object} [fetchOptions={}] Options for the fetch request.
 * @return {object[]} Array of leisure time tips with distance to the given position.
 */
export default async (lat, lng, page = 0, limit = 50, fetchOptions = {}) => {
	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	const response = await fetch(
		`${API_FREIZEITTIPPS_BASE}?lat=${lat}&lng=${lng}&p=${page}&limit=${limit}`,
		fetchOptions,
	);
	return response.json();
	// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
};
