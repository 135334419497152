import { API_BASE } from './constants';

const prIdCache = {};

/**
 * Fetch POIs around the given position
 *
 * @name fetchParkObjectById
 * @function
 * @param {string} poiId The id of the poi.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object} POI for the given id.
 */
export default async (prId, fetchOptions = {}) => {
	if (typeof prIdCache[prId] === 'undefined') {
		const params = [
			'outputFormat=rapidJSON',
			'coordOutputFormat=WGS84[DD.DDDDD]',
			'coordOutputFormatTail=0',
			'hideBannerInfo=1',
			`paID=${prId}`,
			'parkObjInfo=1',
		];

		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		prIdCache[prId] = fetch(
			`${API_BASE}XML_PARKOBJECT_REQUEST?${params.join('&')}`,
			fetchOptions,
		)
			.then(response => response.json())
			.then(json => {
				if (json.locations && json.locations.length) {
					const [prObject] = json.locations;
					if (prObject.properties.realtime) {
						setTimeout(() => {
							delete prIdCache[prId];
						}, 300);
					}
					return prObject;
				}
				delete prIdCache[prId];
				return false;
			});
	}
	return prIdCache[prId];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
