import React from 'react';
import ReactDOM from 'react-dom';

import { toLonLat } from 'ol/src/proj';
import { getBottomLeft, getTopRight } from 'ol/src/extent';

import { fetchers, mapConfig, nameToIcon } from 'shared';

import MarkerButton from './MarkerButton';

import getStationData, { operatorMappings, sharingMappings } from './sharing/getStationData';
import renderSharingTooltip from './renderSharingTooltip';
import { createMarker } from '../../utils';

let sharingOperators;
let lastSettings;

function buildSharingOperators(settings, operators) {
	let result = [];
	Object.keys(settings).forEach(setting => {
		const operator = operators[operatorMappings[setting]];
		if (settings[setting] && operator) {
			result = result.concat(operator);
		}
	});
	return result;
}

/**
 * @param {import('ol/src/Map').default} map
 * @param {object} settings
 * @param {object} operators
 * @param {function} addPoint
 * @param {function} openPopup
 * @param {function} setSharingMarkers
 */
export default async function updateSharingMarkers(
	map,
	settings,
	operators,
	addPoint,
	openPopup,
	setSharingMarkers,
) {
	lastSettings = settings;
	sharingOperators = buildSharingOperators(settings, operators);

	const extent = map.getView().calculateExtent();

	if (sharingOperators.length === 0 || map.getView().getZoom() < mapConfig.minZoomLevel.sharing) {
		setSharingMarkers([]);
		return;
	}

	const bottomLeft = toLonLat(getBottomLeft(extent));
	const topRight = toLonLat(getTopRight(extent));

	const stations = await fetchers.fetchSharingStations(
		bottomLeft[1],
		bottomLeft[0],
		topRight[1],
		topRight[0],
		sharingOperators,
	);

	// sharingOperators could have changed, if the function was triggered again, so we re-check it.
	if (sharingOperators.length === 0 || stations === null) {
		setSharingMarkers([]);
		return;
	}

	const newSharingMarkers = [];

	stations.forEach(station => {
		// Skip stations that are no more in the current settings
		if (!lastSettings[sharingMappings[station.type] || station.type]) {
			return;
		}

		const marker = createMarker(station, {
			type: `sharing_${nameToIcon(sharingMappings[station.type] || station.type)}`,
			operator: sharingMappings[station.type] || station.type,
			vvsId: station.id,
			title: `${station.desc}, ${station.locality}`,
			locationtype: 'station',
			state: 2,
			onHover: () => {
				const stationData = getStationData(station);

				const tooltip = document.getElementById('tooltip');

				// Hack to prevent multiple onClick events on MarkerButton
				ReactDOM.render(<></>, tooltip);
				renderSharingTooltip(
					station,
					operators,
					tooltip,
					<MarkerButton
						className="m-btn--addtoroute"
						onClick={() => {
							// TODO: Check this!
							addPoint({ ...stationData, coord: station.coords });
						}}
					>
						Zur Route hinzufügen
					</MarkerButton>,
					openPopup,
				);

				map.getOverlayById('tooltip').setPosition(marker.getGeometry().getCoordinates());
				tooltip.style.display = '';
			},
		});

		newSharingMarkers.push(marker);
	});

	setSharingMarkers(newSharingMarkers);
}
