import { API_BASE } from './constants';

const coordsCache = {};

/**
 * Fetch stops in a given radius around lat/lng position.
 *
 * @name fetchCoords
 * @function
 * @param {double|string} lat The latitude value of the position.
 * @param {double|string} lng The longitude value of the position.
 * @param {number} [radius=1000] The radius around the position in Meters.
 * @param {number} [limit=-1] The maximum amount of stops being returned (-1 to disable limit).
 * @param {boolean} [distance=true] Add distance to the given position to the returned json.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object[]} Array of stops around the given position.
 */
export default (lat, lng, radius = 1000, limit = -1, distance = true, fetchOptions = {}) => {
	const cacheId = `${lat.toFixed(5)}|${lng.toFixed(5)}|${radius}|${limit}|${distance ? 1 : 0}`;
	if (typeof coordsCache[cacheId] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		coordsCache[cacheId] = fetch(
			`${API_BASE}coords?lat=${lat}&lng=${lng}&radius=${radius}&limit=${limit}&distance=${
				distance ? 1 : 0
			}`,
			fetchOptions,
		).then(response => response.json());
	}
	return coordsCache[cacheId];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
