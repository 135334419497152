import PropTypes from 'prop-types';

export const RoutePropTypes = PropTypes.shape({
	id: PropTypes.string.isRequired,
	image: PropTypes.string,
	title: PropTypes.string.isRequired,
	arrivalTime: PropTypes.string,
	departureTime: PropTypes.string,
	routeSummary: PropTypes.shape({
		bike: PropTypes.object,
		footpath: PropTypes.object,
	}),
	routeType: PropTypes.string.isRequired,
	legs: PropTypes.arrayOf(PropTypes.object),
});

export const ThemeRoutePropTypes = PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	origin: PropTypes.shape({
		place: PropTypes.string.isRequired,
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
	}).isRequired,
	destination: PropTypes.shape({
		place: PropTypes.string.isRequired,
		lat: PropTypes.number,
		lng: PropTypes.number,
	}).isRequired,
	up: PropTypes.number.isRequired,
	down: PropTypes.number.isRequired,
	distance: PropTypes.number.isRequired,
	duration: PropTypes.number.isRequired,
	image: PropTypes.string,
	pdf: PropTypes.string,
});

export const LoadingRoutePropTypes = PropTypes.shape({
	loadingId: PropTypes.number.isRequired,
});
