import attrsArrayToObject from '../attrsArrayToObject';
import { SHARING_API_BASE } from './constants';

const themeRoutesCache = {};

export default function fetchThemeRoutes(id = 0, fetchOptions = {}) {
	if (typeof themeRoutesCache[id] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		themeRoutesCache[id] = fetch(
			`${SHARING_API_BASE}themeroutes${id > 0 ? `/${id}?coordOutputZ=1` : ''}`,
			fetchOptions,
		)
			.then(response => response.text())
			.then(body => {
				try {
					const data = JSON.parse(body);
					return data;
				} catch (e) {
					const data = JSON.parse(`${body}}`);
					return data;
				}
			})
			.then(json => {
				if (id === 0) {
					return json;
				}

				if (json.netPathInfo.id === '-1') {
					throw new Error('No route found.');
				}

				// eslint-disable-next-line no-param-reassign
				json.netPathInfo.attrs = attrsArrayToObject(json.netPathInfo.attrs);
				return json.netPathInfo;
			})
			.catch(e => {
				delete themeRoutesCache[id];
				throw e;
			});
	}

	return themeRoutesCache[id];
}

export async function fetchThemeRouteById(routeId) {
	const routes =
		typeof themeRoutesCache[0] === 'undefined'
			? await fetchThemeRoutes()
			: await themeRoutesCache[0];

	return routes.find(route => route.id === routeId);
}

export async function fetchThemeRoutesMetaById(routeId) {
	const routes =
		typeof themeRoutesCache[0] === 'undefined'
			? await fetchThemeRoutes()
			: await themeRoutesCache[0];

	const index = routes.findIndex(route => route.id === routeId);
	return {
		routesCount: routes.length,
		currentIndex: index + 1,
		nextRoute: (routes[index + 1] && routes[index + 1].id) || null,
		prevRoute: (routes[index - 1] && routes[index - 1].id) || null,
	};
}
