import Fill from 'ol/src/style/Fill';
import markers from '../../assets/marker/combined.svg';

const defaults = {
	anchor: [0.5, 1],
	displacement: [0, -6],
	src: markers,
	imgSize: [138, 1440],
	size: [42, 60],
};

const house = {
	...defaults,
	offset: [4, 900],
};

export const labelStyles = {
	font: '22px Lineto circular black, Helvetica Neue, Helvetica, Arial, sans-serif',
	overflow: true,
	fill: new Fill({
		color: '#fff',
	}),
	textBaseline: 'bottom',
	offsetY: -13,
};

export const zIndex = {
	stop: 10,
	poi_freizeit: 5,
	poi_essentrinken: 5,
	poi_kultur: 5,
	poi_sehenswuerdigkeiten: 5,
	poi_rundumsrad: 5,
	poi_bildungseinrichtungen: 5,
	poi_krankenhaeuserkliniken: 5,
	poi_verbaendevereine: 5,
	sharing_stella: 4,
	sharing_car2go: 4,
	sharing_flinkster: 4,
	sharing_regiorad: 4,
	sharing_stadtmobil: 4,
	tip: 3,
	home: 11,
	singlehouse: 11,
	suburb: 11,
	street: 11,
	address: 11,
	pr: 2,
	pr1: 2,
	pr2: 2,
	pr3: 2,
	start: 20,
	routepoint: 30,
	ziel: 50,
	bike: 12,
};

export default {
	default: {
		...defaults,
		offset: [50, 840],
	},
	stop: {
		...defaults,
		offset: [50, 840],
	},
	poi_freizeit: {
		...defaults,
		offset: [4, 0],
	},
	poi_essentrinken: {
		...defaults,
		offset: [4, 60],
	},
	poi_kultur: {
		...defaults,
		offset: [4, 120],
	},
	poi_sehenswuerdigkeiten: {
		...defaults,
		offset: [4, 180],
	},
	poi_rundumsrad: {
		...defaults,
		offset: [4, 240],
	},
	poi_bildungseinrichtungen: {
		...defaults,
		offset: [4, 300],
	},
	poi_krankenhaeuserkliniken: {
		...defaults,
		offset: [4, 360],
	},
	poi_verbaendevereine: {
		...defaults,
		offset: [4, 420],
	},
	sharing_stella: {
		...defaults,
		offset: [4, 480],
	},
	sharing_car2go: {
		...defaults,
		offset: [4, 540],
	},
	sharing_flinkster: {
		...defaults,
		offset: [4, 600],
	},
	sharing_regiorad: {
		...defaults,
		offset: [4, 660],
	},
	sharing_stadtmobil: {
		...defaults,
		offset: [4, 720],
	},
	tip: {
		...defaults,
		offset: [4, 780],
	},
	home: house,
	singlehouse: house,
	suburb: house,
	street: house,
	address: house,
	pr: {
		...defaults,
		offset: [4, 960],
	},
	pr1: {
		...defaults,
		offset: [4, 1140],
	},
	pr2: {
		...defaults,
		offset: [4, 1080],
	},
	pr3: {
		...defaults,
		offset: [4, 1020],
	},
	start: {
		...defaults,
		anchor: [0.5, 0.5],
		displacement: [0, 0],
		offset: [4, 1320],
	},
	routepoint: {
		...defaults,
		offset: [50, 1200],

		// Todo: Add text styles
	},
	ziel: {
		...defaults,
		offset: [50, 1260],
	},
	bike: {
		...defaults,
		offset: [50, 1380],
	},
	position: {
		anchor: [0.5, 0.5],
		displacement: [0, 0],
		imgSize: [32, 32],
		size: [32, 32],
	},
};
