import { API_BASE } from './constants';

const locationSearchCache = {};

/**
 * Search for stops and locations with the given input.
 *
 * @name searchLocations
 * @function
 * @param {string} input        The input string.
 * @param {object} fetchOptions Options for the fetch request.
 * @return {object[]} An array of stop objects.
 */
export default async (input, fetchOptions = {}) => {
	if (!input) {
		return [];
	}
	if (typeof locationSearchCache[input] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		const response = await fetch(`${API_BASE}?q=${encodeURIComponent(input)}`, fetchOptions);
		locationSearchCache[input] = await response.json();
	}
	return locationSearchCache[input];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
