import { getObjectSubtree } from 'shared';

export const operatorMappings = {
	car2go: 'Car2Go',
	flinkster: 'Flinkster',
	regioRad: 'CallABike',
	stadtMobil: 'stadtmobil',
	stella: 'stella',
};

export const operatorTitles = {
	...operatorMappings,
	car2go: 'ShareNow',
	regioRad: 'RegioRad',
	stadtMobil: 'Stadtmobil',
	stella: 'Stella',
};

export const sharingMappings = {
	stella: 'stella',
	STADTMOBIL: 'stadtMobil',
	FLINKSTER: 'flinkster',
	car2go: 'car2go',
	CALL_A_BIKE: 'regioRad',
	CallABike: 'regioRad',
};

const carClasses = {
	SMALLFAMILYCAR: 'Kleines Familienauto',
	CITYCAR: 'Stadtauto',
	MULTIPURPOSECARS: 'Mehrzweckfahrzeug',
	LARGEFAMILYCAR: 'Großes Familienauto',
	SUPERMINI: 'Kleinwagen',
	LUXURYCAR: 'Luxus',
	MOTORCYCLE: 'Motorrad / Motorroller',
	SCOOTER: 'Motorroller',
};

function getVehicleClass(vehicle) {
	if (vehicle.vehType === 'BIKE') {
		return vehicle.catName;
	}
	if (vehicle.vehType === 'MOTORCYCLE') {
		return carClasses[vehicle.motorcycleClass];
	}
	return carClasses[vehicle.carClass];
}

function getUsedClasses(vehicles) {
	const usedClasses = {};
	if (Array.isArray(vehicles)) {
		vehicles.forEach(vehicle => {
			const vehicleClass = getVehicleClass(vehicle);
			if (typeof usedClasses[vehicleClass] === 'undefined') {
				usedClasses[vehicleClass] = 0;
			}
			usedClasses[vehicleClass] += 1;
		});
	} else {
		usedClasses[getVehicleClass(vehicles)] = 1;
	}
	return usedClasses;
}

function buildAddress(addressString) {
	const addressData = addressString.split(', ').filter(data => data.trim());
	return addressData;
}

export default function getStationData(station) {
	let vehicleTypes = {};
	let freeVehicles = 1;
	let isElectric = false;
	let hasMultiple = false;
	let fuelLevel;
	const addressField =
		getObjectSubtree(station, ['coordInfoDetails', 'station', 'addr']) ||
		getObjectSubtree(station, ['coordInfoDetails', 'addr']);
	const address = buildAddress(addressField);
	const vehDetails = getObjectSubtree(station, ['coordInfoDetails', 'station', 'vehDetails']);

	if (typeof vehDetails !== 'undefined') {
		vehicleTypes = getUsedClasses(vehDetails);

		if (Array.isArray(vehDetails)) {
			freeVehicles = vehDetails.length;
			hasMultiple = true;
		} else {
			isElectric = vehDetails.engine === 'ELECTRIC';
			fuelLevel = vehDetails.fuelLevel;
		}
	} else {
		vehicleTypes = getUsedClasses(station.coordInfoDetails);
		isElectric = station.coordInfoDetails.engine === 'ELECTRIC';
		fuelLevel = station.coordInfoDetails.fuelLevel;
	}

	const type = sharingMappings[station.type] || station.type;
	return {
		id: station.stateless,
		name: station.desc,
		title: station.desc,
		freeVehicles,
		isElectric,
		fuelLevel,
		address,
		hasMultiple,
		vehicleTypes,
		type: 'sharing',
		icon: type.toLowerCase(),
	};
}
