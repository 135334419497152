import React from 'react';
import ReactDOM from 'react-dom';

import { getBottomLeft, getTopRight } from 'ol/src/extent';
import { toLonLat } from 'ol/src/proj';

import { fetchers, mapConfig, nameToIcon } from 'shared';

import MarkerButton from './MarkerButton';
import { createMarker } from '../../utils';

let latestSettings;

const settingToLetter = {
	educationalInstitutions: 'B',
	recreation: 'C',
	hospitals: 'F',
	culture: 'G',
	landmarks: 'H',
	clubs: 'J',
	foodAndDrink: 'K',
	aroundBike: 'M',
};

const categoryToSetting = {
	Bildungseinrichtungen: 'educationalInstitutions',
	Freizeit: 'recreation',
	'Krankenhäuser & Kliniken': 'hospitals',
	Kultur: 'culture',
	Sehenswürdigkeiten: 'landmarks',
	'Verbände & Vereine': 'clubs',
	'Essen & Trinken': 'foodAndDrink',
	'Rund ums Rad': 'aroundBike',
};

/**
 *
 * @param {import('ol/src/Map').default} map
 * @param {object} settings
 * @param {function} addPoint
 * @param {function} setPoiMarkers
 * @returns
 */
export default async function updatePOIMarkers(map, settings, addPoint, setPoiMarkers) {
	latestSettings = settings;

	if (map.getView().getZoom() < mapConfig.minZoomLevel.pois) {
		setPoiMarkers([]);
		return;
	}

	const extent = map.getView().calculateExtent();
	const bottomLeft = toLonLat(getBottomLeft(extent));
	const topRight = toLonLat(getTopRight(extent));

	const usedCategories = Object.keys(latestSettings)
		.filter(category => latestSettings[category])
		.map(category => settingToLetter[category]);

	let pois = await fetchers.fetchPOIsByArea(
		bottomLeft[1],
		bottomLeft[0],
		topRight[1],
		topRight[0],
		usedCategories,
	);

	// Filter out pois with invalid categories (if categories have changed since starting the request)
	pois = pois.filter(poi => latestSettings[categoryToSetting[poi.category]]);

	const newPoiMarkers = [];

	// Draw new markers
	pois.forEach(poi => {
		const marker = createMarker(poi, {
			type: `poi_${nameToIcon(poi.category)}`,
			title: poi.name,
			subtitle: poi.parent.name,
			address: poi.address || '',
			category: poi.category,
			locationtype: 'poi',
			vvsId: poi.id,
			state: 2,
			onHover: () => {
				const tooltip = document.getElementById('tooltip');

				// Hack to prevent multiple onClick events on MarkerButton
				ReactDOM.render(<></>, tooltip);
				ReactDOM.render(
					<div className="marker__tooltip marker__tooltip--poi">
						<h2 className="tooltipcontent__title">{poi.name}</h2>
						<MarkerButton
							className="m-btn--addtoroute"
							onClick={e => {
								e.preventDefault();
								addPoint(poi);
							}}
						>
							Zur Route hinzufügen
						</MarkerButton>
					</div>,
					tooltip,
				);

				map.getOverlayById('tooltip').setPosition(marker.getGeometry().getCoordinates());
				tooltip.style.display = '';
			},
		});

		newPoiMarkers.push(marker);
	});

	setPoiMarkers(newPoiMarkers);
}
