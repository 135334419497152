import { API_BASE } from './constants';

/**
 * Fetch POIs around the given position
 *
 * @name fetchPOIs
 * @function
 * @param {double|string} lat The latitude value of the position.
 * @param {double|string} lng The longitude value of the position.
 * @param {number} [limit=5] Limit of the POIs that should be retrieved.
 * @param {number} [page=0] Optional parameter for pagination.
 * @param {string|null} [category=null] Fetch POIs for the given category only.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object[]} Array of POIs around the given position.
 */
export default async (lat, lng, limit = 5, page = 0, category = null, fetchOptions = {}) => {
	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	const response = await fetch(
		`${API_BASE}pois?lat=${lat}&lng=${lng}&limit=${limit}${page > 0 ? `&page=${page}` : ''}${
			category !== null ? `&category=${encodeURIComponent(category)}` : ''
		}`,
		fetchOptions,
	);
	return response.json();
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
