import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import VectorSource from 'ol/src/source/Vector';
import Feature from 'ol/src/Feature';
import Point from 'ol/src/geom/Point';
import { fromLonLat } from 'ol/src/proj';

import {
	OutlineButton,
	SidebarHeader,
	colors,
	createGpx,
	download,
	getMinMaxCoordsOfRoute,
	replaceUmlauts,
	getSearchLabelFromResult,
	getCoordOfPoint,
} from 'shared';

import context from '../../context';
import {
	getDuration,
	getJoinedLegs,
	getRouteSummary,
	distanceToString,
	getOrCreateLinesLayer,
	drawRouteOnMap,
	activateRouteType,
	enterLayer,
	mapRoot,
	updateUri,
} from '../../../utils';

import { ResultTime } from '../ResultStyles';
import RouteLeg from './RouteLeg';

import { Footpath, Bike, ArrowDown, ArrowUp } from '../../../assets/icons';
import useDrawLegsTransportationMarkers from '../../../hooks/useDrawLegsTransportationMarkers';

const HeaderContent = styled.div`
	padding: 1rem 1rem 1rem 4rem;
	width: 100%;
`;

const Content = styled.div`
	padding: 1rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	* ~ * {
		margin-top: 1rem;
	}
`;

const Hr = styled.div`
	margin: 1rem 0;
	border-bottom: 1px solid ${colors.graylight};
`;

const RouteTypeHeadline = styled.h3`
	margin-bottom: 0.2rem;
`;

const RouteData = styled.div`
	display: flex;
	flex-direction: column;
`;

const AdditionalInfoEntry = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content max-content max-content;

	span {
		display: flex;
		align-items: center;
		padding-right: 0.5rem;
	}
	svg {
		width: 1.66rem;
		height: 1.66rem;
		&.mr {
			margin-right: 0.3rem;
		}
	}
`;

function AdditionalInfo({ info, text, icon: Icon }) {
	return (
		<AdditionalInfoEntry>
			<span>
				<Icon className="mr" />
				{text}
			</span>
			<span>{distanceToString(info.distance)}</span>
			<span>
				<ArrowUp />
				{distanceToString(info.distanceUp)}
			</span>
			<span>
				<ArrowDown />
				{distanceToString(info.distanceDown)}
			</span>
		</AdditionalInfoEntry>
	);
}

AdditionalInfo.propTypes = {
	info: PropTypes.shape({
		distance: PropTypes.number,
		distanceDown: PropTypes.number,
		distanceUp: PropTypes.number,
	}).isRequired,
	text: PropTypes.string.isRequired,
	icon: PropTypes.any,
};

export default function RouteDetails({
	route,
	customPoints,
	savePointsInGpx,
	onBack,
	changeUri = true,
}) {
	const { map, addMyRoute, points } = useContext(context);
	const { t } = useTranslation();
	const setLegs = useDrawLegsTransportationMarkers();

	const usedPoints = customPoints || points;

	const { origin } = route.legs[0];
	const { destination } = route.legs[route.legs.length - 1];
	const duration = getDuration(origin, destination);
	const legs = getJoinedLegs(
		route.legs,
		route.routeType === 'bikeSharing' ? 'RegioRad' : 'Fahrrad',
	);

	const routeSummary = getRouteSummary(route);

	useEffect(() => {
		if (typeof _paq !== 'undefined') {
			// eslint-disable-next-line no-underscore-dangle
			window._paq.push([
				'trackEvent',
				'Routenplanung', // Category
				'Detailansicht', // Action
				`${t(`routePlanning.${route.routeType}`)}: ${points
					.filter(Boolean)
					.map(point => getSearchLabelFromResult(point))
					.join(' - ')}`, // Name
			]);
		}
	}, [points, route.routeType, t]);

	useEffect(() => {
		if (!map) return;

		if (changeUri) {
			updateUri(false, `${mapRoot}/routenplanung/details`);
		}

		const lineLayer = getOrCreateLinesLayer(map, 'lines');
		lineLayer.setSource(new VectorSource());

		drawRouteOnMap(map, route, route.routeType);
		activateRouteType(lineLayer, route.routeType);
		enterLayer(lineLayer, route.routeType, false);
		setLegs(route.legs);

		const data = getMinMaxCoordsOfRoute(route);
		if (data === false) {
			return;
		}

		const features = [
			new Feature(new Point(fromLonLat([data.minLon, data.minLat]))),
			new Feature(new Point(fromLonLat([data.minLon, data.maxLat]))),
			new Feature(new Point(fromLonLat([data.maxLon, data.minLat]))),
			new Feature(new Point(fromLonLat([data.maxLon, data.maxLat]))),
		];

		points.forEach(point => {
			const coord = getCoordOfPoint(point);
			features.push(new Feature(new Point(fromLonLat([coord[1], coord[0]]))));
		});

		const boundSources = new VectorSource();
		boundSources.addFeatures(features);

		try {
			map.getView().fit(boundSources.getExtent(), {
				size: map.getSize(),
				padding: [50, 50, 50, 50],
			});
		} catch (e) {
			console.error(e);
		}
	}, [map, route, changeUri, points, setLegs]);

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.white}
				onBack={onBack}
				backButtonColor={colors.cyandark}
				isSmall
			>
				<HeaderContent></HeaderContent>
			</SidebarHeader>
			<Content>
				<RouteTypeHeadline>{t(`routePlanning.${route.routeType}`)}</RouteTypeHeadline>
				<ResultTime>{duration}</ResultTime>
				{/* <TransportationWrapper>
					{legs.map(leg => (
						<Transportation
							key={`route-${route.routeType}-${leg.origin.departureTimePlanned}`}
							leg={leg}
						/>
					))}
				</TransportationWrapper> */}

				<Hr />
				<RouteData>
					{legs.map((leg, index) => (
						<RouteLeg
							key={leg?.origin?.id + leg?.destination?.id}
							route={route}
							leg={leg}
							index={index}
							legs={legs}
						/>
					))}
				</RouteData>
				{(routeSummary.footpath.duration > 0 || routeSummary.bike.duration > 0) && (
					<>
						<Hr />

						<div>
							{routeSummary.footpath.duration > 0 && (
								<AdditionalInfo
									info={routeSummary.footpath}
									text={t('routeDetails.byFoot')}
									icon={Footpath}
								/>
							)}
							{routeSummary.bike.duration > 0 && (
								<AdditionalInfo
									info={routeSummary.bike}
									text={t('routeDetails.byBike')}
									icon={Bike}
								/>
							)}
						</div>
					</>
				)}
			</Content>
			<ButtonWrapper>
				<OutlineButton type="button" onClick={addMyRoute} disabled={route.saved}>
					{route.saved ? t('routePlanning.saved') : t('routePlanning.save')}
				</OutlineButton>
				<OutlineButton
					type="button"
					onClick={() => {
						let name = `${route.routeType}.gpx`;
						if (legs && legs.length > 0) {
							const start = replaceUmlauts(legs[0].origin.name);
							const end = replaceUmlauts(legs[legs.length - 1].destination.name);
							name = `${start}-nach-${end}.gpx`;
						}
						download(
							createGpx(usedPoints, legs, 'VVS Radroutenplaner', savePointsInGpx),
							'application/javascript',
							name,
						);
					}}
				>
					{t('routePlanning.downloadGpx')}
				</OutlineButton>
			</ButtonWrapper>
		</>
	);
}

RouteDetails.propTypes = {
	route: PropTypes.object.isRequired,
	onBack: PropTypes.func.isRequired,
	customPoints: PropTypes.array,
	savePointsInGpx: PropTypes.bool,
	changeUri: PropTypes.bool,
};
