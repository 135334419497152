import React from 'react';
import ReactDOM from 'react-dom';

import Feature from 'ol/src/Feature';
import Point from 'ol/src/geom/Point';
import VectorSource from 'ol/src/source/Vector';
import { fromLonLat } from 'ol/src/proj';
// import MouseWheelZoom from 'ol/src/interaction/MouseWheelZoom';

import {
	// fetchers,
	getCoordOfPoint,
	getSearchLabelFromResult,
	removeDuplicateStrings,
} from 'shared';

import MarkerButton from '../MapMarkers/MarkerButton';

import { getOrCreateLinesLayer, hashId } from '../../utils';

// function activateMouseWheelZoom(map, active) {
// 	map.getIterations().forEach(interaction => {
// 		if (interaction instanceof MouseWheelZoom) {
// 			interaction.setActive(active);
// 		}
// 	});
// }

function createMarker(map, point, clazz, label, removePoint, updatePoint) {
	const coord = getCoordOfPoint(point);
	const lonLat = fromLonLat([coord[1], coord[0]]);
	const title = getSearchLabelFromResult(point);

	const marker = new Feature({
		type: 'routepoint',
		label,
		draggable: true,
		internalType: 'point',
		geometry: new Point(lonLat),
		onHover: () => {
			const tooltip = document.getElementById('tooltip');

			// Hack to prevent multiple onClick events on MarkerButton
			ReactDOM.render(<></>, tooltip);
			ReactDOM.render(
				<div className="marker__tooltip">
					<h3 className="tooltipcontent__title">{title}</h3>
					<MarkerButton
						onClick={e => {
							e.preventDefault();
							// Remove point from active points
							removePoint(point);
							tooltip.style.display = 'none';
						}}
					>
						Von Route entfernen
					</MarkerButton>
				</div>,
				tooltip,
			);

			map.getOverlayById('tooltip').setPosition(lonLat);
			tooltip.style.display = '';
		},
	});
	// marker.vvsType = point.type;
	// marker.type = 'point';

	// let initialX;
	// let initialY;
	// let xOffset;
	// let yOffset;

	// const { imageDiv } = marker.icon;
	// imageDiv.classList.add('marker');
	// imageDiv.classList.add('marker--draggable');
	// imageDiv.classList.add(`marker--${clazz}`);

	// const drag = e => {
	// 	e.preventDefault();
	// 	e.stopPropagation();

	// 	let source;
	// 	if (e.type === 'touchmove') {
	// 		[source] = e.touches;
	// 	} else {
	// 		source = e;
	// 	}

	// 	xOffset = source.clientX - initialX;
	// 	yOffset = source.clientY - initialY;

	// 	imageDiv.style.transform = `translate3d(${xOffset}px, ${yOffset}px, 0)`;
	// };

	// const dragEnd = () => {
	// 	const { map } = markerLayer;
	// 	const { layerContainerDiv } = map;
	// 	layerContainerDiv.removeEventListener('touchmove', drag, false);
	// 	layerContainerDiv.removeEventListener('touchend', dragEnd, false);

	// 	layerContainerDiv.removeEventListener('mousemove', drag, false);
	// 	layerContainerDiv.removeEventListener('mouseup', dragEnd, false);

	// 	document.removeEventListener('keydown', cancelDragOnEsc, false);

	// 	const initialPixel = map.getPixelFromLonLat(marker.lonlat);
	// 	const finalPixel = {
	// 		x: initialPixel.x + xOffset,
	// 		y: initialPixel.y + yOffset,
	// 	};

	// 	if (xOffset === 0 && yOffset === 0) {
	// 		return;
	// 	}

	// 	const newLonLat = map.getLonLatFromPixel(finalPixel);

	// 	marker.lonlat = newLonLat;

	// 	activateMouseWheelZoom(map, true);

	// 	const wgs84 = toEpsg4326(newLonLat.lat, newLonLat.lon);

	// 	const tooltipTitle = imageDiv.querySelector('.tooltipcontent__title');
	// 	if (tooltipTitle !== null) {
	// 		tooltipTitle.innerText = 'Lade Adresse...';
	// 		fetchers.fetchCoordData(wgs84.lat, wgs84.lon).then(json => {
	// 			if (json.locations.length > 0) {
	// 				markerLayer.removeMarker(marker);
	// 				const [location] = json.locations;
	// 				// This will redraw the markers
	// 				updatePoint(location);
	// 			}
	// 		});
	// 	}
	// };

	// const cancelDragOnEsc = e => {
	// 	if (e.which === 27) {
	// 		const { map } = markerLayer;
	// 		const { layerContainerDiv } = map;
	// 		layerContainerDiv.removeEventListener('touchmove', drag, false);
	// 		layerContainerDiv.removeEventListener('touchend', dragEnd, false);

	// 		layerContainerDiv.removeEventListener('mousemove', drag, false);
	// 		layerContainerDiv.removeEventListener('mouseup', dragEnd, false);

	// 		document.removeEventListener('keydown', cancelDragOnEsc, false);

	// 		imageDiv.style.transform = `translate3d(0, 0, 0)`;

	// 		activateMouseWheelZoom(map, true);
	// 	}
	// };

	// const dragStart = e => {
	// 	e.preventDefault();
	// 	e.stopPropagation();

	// 	if (e.type === 'touchstart') {
	// 		initialX = e.touches[0].clientX;
	// 		initialY = e.touches[0].clientY;
	// 	} else {
	// 		initialX = e.clientX;
	// 		initialY = e.clientY;
	// 	}

	// 	const { map } = markerLayer;
	// 	const { layerContainerDiv } = map;

	// 	activateMouseWheelZoom(map, false);

	// 	layerContainerDiv.addEventListener('touchmove', drag, false);
	// 	layerContainerDiv.addEventListener('touchend', dragEnd, false);

	// 	layerContainerDiv.addEventListener('mousemove', drag, false);
	// 	layerContainerDiv.addEventListener('mouseup', dragEnd, false);

	// 	document.addEventListener('keydown', cancelDragOnEsc, false);
	// };

	// imageDiv.addEventListener('touchstart', dragStart, false);
	// imageDiv.addEventListener('mousedown', dragStart, false);

	// const markerTooltip = document.createDocumentFragment();
	// ReactDOM.render(
	// 	<div className="marker__tooltip">
	// 		<h3 className="tooltipcontent__title">{point.name}</h3>
	// 		<MarkerButton
	// 			onClick={e => {
	// 				e.preventDefault();
	// 				// Remove point from active points
	// 				removePoint(point);
	// 			}}
	// 		>
	// 			Von Route entfernen
	// 		</MarkerButton>
	// 	</div>,
	// 	markerTooltip,
	// );
	// imageDiv.appendChild(markerTooltip);

	return marker;
}

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function updatePointMarkers(
	map,
	points,
	removePoint,
	resetLines,
	setPoints,
	setPointMarkers,
) {
	if (map === null) return;

	const pointMarkers = [];

	points.forEach((point, i) => {
		if (point === null) return;
		const label = ALPHABET.charAt(i);

		const pointMarker = createMarker(map, point, 'routepoint', label, removePoint, newPoint => {
			const newPoints = [...points];
			newPoints[i] = newPoint;

			setPoints(newPoints, true, false);
		});

		pointMarkers.push(pointMarker);
	});

	setPointMarkers(pointMarkers);

	if (resetLines) {
		/** @type {import('ol/src/layer/Vector').default} */
		const linesLayer = getOrCreateLinesLayer(map);
		linesLayer.setSource(new VectorSource());
	}
}

export function relocateMap(map, points) {
	if (map !== null) {
		const boundSources = new VectorSource();
		let hasEntries = false;
		points.forEach(point => {
			if (point !== null) {
				hasEntries = true;
				const coord = getCoordOfPoint(point);
				boundSources.addFeature(
					new Feature({
						geometry: new Point(fromLonLat([coord[1], coord[0]])),
					}),
				);
			}
		});
		if (hasEntries) {
			map.getView().fit(boundSources.getExtent(), {
				size: map.getSize(),
				padding: [50, 50, 50, 50],
			});
		}
	}
}

/**
 * Generates a myRoute entry
 * @param {object} route  A route item
 * @param {array}  points The current points for the route
 * @param {Date}   date   The date for the request
 * @return {object} a myRoutes item
 */
export function generateMyRoute(route, points, date) {
	const { origin } = route.legs[0];
	const { destination } = route.legs[route.legs.length - 1];

	const dateString = date
		.getUTCFullYear()
		.toString()
		.concat(
			'-',
			`0${date.getUTCMonth() + 1}`.substr(-2),
			'-',
			`0${date.getUTCDate()}`.substr(-2),
			'T',
			`0${date.getUTCHours()}`.substr(-2),
			':',
			`0${date.getUTCMinutes()}`.substr(-2),
			':',
			`0${date.getUTCSeconds()}`.substr(-2),
		);

	const start = removeDuplicateStrings(origin.name, origin.disassembledName).join(', ');
	const end = removeDuplicateStrings(destination.name, destination.disassembledName).join(', ');

	return {
		my: true,
		id: hashId(),
		title: `${start} - ${end}`,
		points: points.map(point => ({
			id: point.id,
			name: point.name,
			disassembledName: point.disassembledName,
			shownLabel: point.shownLabel,
			type: point.type,
			...(point.type === 'tip' && {
				title: point.title,
				address: {
					city: point.address.city,
					coords: point.address.coords,
				},
			}),
			...(point.type === 'STOP' && {
				desc: point.desc,
				locality: point.locality,
			}),
			...(point.parent && { parent: { name: point.parent.name } }),
			...(point.coord && { coord: point.coord }),
			...(point.coords && { coords: point.coords }),
		})),
		routeType: route.routeType,
		departureTime: origin.departureTimePlanned,
		arrivalTime: destination.arrivalTimePlanned,
		coords: [origin.coord, destination.coord],
		legs: route.legs.map(leg => ({
			duration: leg.duration,
			distance: leg.distance,
			transportation: {
				product: {
					name: leg?.transportation?.product?.name,
				},
			},
		})),
		date: dateString,
	};
}
