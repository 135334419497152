export const mapConfig = {
	attribution:
		'&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
	center: [48.7835562, 9.1814453],
	maxExtent: [8.439607589923188, 48.31375945387407, 10.253652220267472, 49.287850433238845],
	zoom: 17,
	minZoom: 10,
	maxZoom: 18,
	requireCtrl: false,
	// requireCtrl: 1280,
	minZoomLevel: {
		// This is minZoom + zoomLevel defined here.
		pois: 16,
		positions: 12,
		sharing: 13,
		stops: 16,
		tipps: 16,
		parkAndRide: 13,
	},
};

export const colors = {
	orange: '#fc631e', // 252, 99, 30

	white: '#fff',
	black: '#000',

	grayblue: '#98AAB3',
	graydark: '#6C7A80',

	graylight: '#e6e6e6', // 230, 230, 230
	graysuperlight: '#f6f6f6',

	cyandark: '#097fac', // 9, 127, 172
	purple: '#8269ad', // 130, 105, 173
	violetred: '#c24688', // 194, 70, 136
	green: '#008484', // 0, 132, 132

	footpath: '#fc631e',
	bike: '#097fac',
	regioRad: '#06507f',
	rBahn: '#8F908F',
	sBahn: '#52B331',
	bus: '#C31924',
	zacke: '#FDCC00',
	stadtbahn: '#0AA1E2',
	taxi: '#183f8a',
};

export const fonts = {
	default: 'Lineto circular book, Helvetica Neue, Helvetica, Arial, sans-serif',
	bold: 'Lineto circular bold, Helvetica Neue, Helvetica, Arial, sans-serif',
	black: 'Lineto circular black, Helvetica Neue, Helvetica, Arial, sans-serif',

	weightdefault: 400,
	weightbold: 700,
	weightblack: 400,
};

export const boxshadow = {
	default:
		'0px 4px 1.6px -2px rgba(0, 0, 0, 0.04), 0px -4px 12px -2px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.08), 0px 6px 12px -2px rgba(0, 0, 0, 0.4)',

	tooltip: '0 2px 10px rgba(0, 0, 0, 0.25)',
	categorymenu: '0 6px 10px rgba(0, 0, 0, 0.25)',
	menutileicon: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
	strong: `
		0 2px 4px 0 rgba(0, 0, 0, 0.3),
		0 2px 8px 0 rgba(0, 0, 0, 0.15),
		0 2px 16px 0 rgba(0, 0, 0, 0.1)`,

	hover:
		'0px 4px 3px 0px rgba(0, 0, 0, 0.04), 0px -4px 16px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.08), 0px 12px 16px 0px rgba(0, 0, 0, 0.04)',
	focus:
		'0px 4px 5px 0px rgba(0, 0, 0, 0.06), 0px -4px 20px 0px rgba(0, 0, 0, 0.08), 0px 4px 16px 0px rgba(0, 0, 0, 0.1), 0px 12px 24px 0px rgba(0, 0, 0, 0.07)',
};
