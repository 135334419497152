import React from 'react';
import ReactDOM from 'react-dom';
import { toLonLat } from 'ol/src/proj';
import { getHeight, getWidth } from 'ol/src/extent';

import { fetchers, mapConfig } from 'shared';

import MarkerButton from './MarkerButton';
import { createMarker } from '../../utils';

let showMarkersValue;

/**
 *
 * @param {import('ol/src/Map').default} map
 * @param {boolean} showMarkers
 * @param {function} addPoint
 * @returns
 */
export default async function updateStopMarkers(map, showMarkers, addPoint, setStopMarkers) {
	showMarkersValue = showMarkers;

	const filteredMeans = ['1', '2', '3', '6'];

	if (showMarkers === false || map.getView().getZoom() < mapConfig.minZoomLevel.stops) {
		// If zoom is < 15 or markers should be hidden, remove all stop markers and stop further calculation etc.
		setStopMarkers([]);
		return;
	}

	const extent = map.getView().calculateExtent();
	const [lon, lat] = toLonLat(map.getView().getCenter());
	const width = getWidth(extent);
	const height = getHeight(extent);
	const diameter = Math.max(width, height);
	const radius = Math.ceil(diameter / 2);

	const stops = await fetchers.fetchCoords(lat, lon, radius);

	// Stop processing, if filter changed
	if (showMarkersValue === false) {
		return;
	}

	const newStopMarkers = [];

	stops.pins.forEach(stop => {
		// Check, if stop means is in filtered means
		if (!filteredMeans.some(entry => stop.attrs.STOP_MEANS_LIST.indexOf(entry) > -1)) {
			return;
		}

		const marker = createMarker(stop, {
			type: 'stop',
			state: 2,
			title: `${stop.desc}, ${stop.locality}`,
			desc: stop.desc,
			locality: stop.locality,
			vvsId: stop.attrs.STOP_GLOBAL_ID,
			vvsMeans: stop.attrs.STOP_MEANS_LIST,
			onHover: () => {
				const tooltip = document.getElementById('tooltip');

				map.getOverlayById('tooltip').setPosition(marker.getGeometry().getCoordinates());
				tooltip.style.display = '';

				// Hack to prevent multiple onClick events on MarkerButton
				ReactDOM.render(<></>, tooltip);
				ReactDOM.render(
					<div className="marker__tooltip marker__tooltip--stop">
						<h2 className="tooltipcontent__title">
							{stop.desc}, {stop.locality}
						</h2>
						<MarkerButton
							className="m-btn--addtoroute"
							onClick={e => {
								tooltip.style.display = 'none';
								e.preventDefault();
								e.stopPropagation();
								addPoint(stop);
							}}
						>
							Zur Route hinzufügen
						</MarkerButton>
					</div>,
					tooltip,
				);
			},
		});

		newStopMarkers.push(marker);
	});

	setStopMarkers(newStopMarkers);
}
