import styled from 'styled-components';
import { colors } from '../../utils/config';
import media from '../../utils/Breakpoints';

import IconMapLayerDefault from '../../assets/icons/map-layer-default--new.svg';
import IconMapLayerHover from '../../assets/icons/map-layer-hover--new.svg';

export default styled.div`
	/* background-color: ${colors.cyandark}; */
	position: fixed;
	top: ${({ pullUp }) => (pullUp ? 8 : 72)}px;
	right: 8px;
	width: 27px;
	height: 27px;
	background: url(${({ icon }) => icon || IconMapLayerDefault}) center center no-repeat
	${colors.cyandark};
	background-size: 100%;
	border-radius: 5px;
	z-index: 10;

	${media.iphone`
		top: 8px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(0.752);
		transform-origin: top right;
	}

	div {
		display: none;
	}

	&:hover {
		cursor: pointer;
		background-image: url(${({ iconHover }) => iconHover || IconMapLayerHover});

		div {
			display: block;
		}
	}
`;
