import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VectorSource from 'ol/src/source/Vector';
import Overlay from 'ol/src/Overlay';

import {
	getSearchLabelFromResult,
	IconProps,
	MapComponent as Map,
	MapWrapper,
	Menu,
	MenuTile,
	Popup,
	routeCache,
	Search,
	SettingsButton,
	Tooltip,
} from 'shared';

import { useHistory } from 'react-router';
import Context from './components/context';

import Debug from './components/debug';
import SettingsSidebar from './components/SettingsSidebar';
import LeftSidebar from './components/LeftSidebar';
import useMapMarkers from './hooks/useMapMarkers';

import {
	clearMyTimeout,
	drawRouteOnMap,
	getOrCreateLinesLayer,
	initMapEvents,
	mapRoot,
	// leaveLayer,
	routeTypes,
	setMyTimeout,
	updateUri,
} from './utils';

import MapModal from './components/map-modal';
import SavedModal from './components/saved-modal';

import radrouten from './assets/images/radrouten.jpg';
import meineRouten from './assets/images/meine-routen.jpg';
import pageRadroutenplaner from './assets/images/card.jpg';
import { RadroutenPlanerIcon } from './assets/icons';

function App() {
	const {
		toggleLeftSidebar,
		toggleSettingsSidebar,
		isLeftSidebarOpen,
		routes,
		myRoutes,
		points,
		setPoints,
		addPoint,
		map,
		setMap,
		popup,
		openPopup,
		mapType,
		setActiveRoute,
		setHoverRoute,
		routePlanningActive,
		setRoutePlanningActive,
		stopMarkers,
		positionMarkers,
		poiMarkers,
		sharingMarkers,
		mobilityIcons,
		pointMarkers,
		bikeMarker,
		locationMarker,
		setLocationMarker,
	} = useContext(Context);

	const history = useHistory();

	const [position, setPosition] = useState();

	const tooltip = useRef();

	const lastMap = useRef();
	useMapMarkers({ position });

	const { t } = useTranslation();

	const shouldDebug = false;

	useEffect(() => {
		if (map !== null) {
			tooltip.current = document.getElementById('tooltip');

			const overlay = new Overlay({
				id: 'tooltip',
				element: tooltip.current,
				offset: [0, 0],
				positioning: 'top-center',
			});
			map.addOverlay(overlay);
		}
	}, [map]);

	useEffect(() => {
		if (map !== null && lastMap.current !== map) {
			lastMap.current = map;

			initMapEvents(map, {
				onFeatureEnter: setHoverRoute,
				onFeatureActivate: routeType => {
					setActiveRoute(routeType);
					if (routeTypes.indexOf(routeType) > -1) {
						updateUri(false, `${mapRoot}/routenplanung`);
						toggleLeftSidebar(true);
					}
				},
				onFeatureDeactivate: () => setActiveRoute(null),
				addPoint: (point, inBetween = true) => {
					setActiveRoute(null);
					addPoint(point, inBetween);
				},
				setLocationMarker,
			});
		}
	}, [map, setActiveRoute, setHoverRoute, addPoint, toggleLeftSidebar, setLocationMarker]);

	useEffect(() => {
		if (map !== null && lastMap.current !== map) {
			if (routes.length) {
				routes.forEach(route => {
					if (route) {
						drawRouteOnMap(map, route, route.routeType);
					}
				});
			}
		}
	}, [map, routes]);

	useEffect(() => {
		if (map !== null && window.innerWidth > 668) {
			clearMyTimeout('mapRegion');
			setMyTimeout(
				'mapRegion',
				() => {
					if (isLeftSidebarOpen) {
						if (window.innerWidth >= 1280) {
							map.getTargetElement().style.left = '432px';
						} else {
							map.getTargetElement().style.left = '375px';
						}
					} else {
						map.getTargetElement().style.left = 0;
					}
					map.updateSize();
				},
				500,
			);
		}

		return () => {
			clearMyTimeout('mapRegion');
		};
	}, [map, isLeftSidebarOpen]);

	useEffect(() => {
		if (!map) return;
		const linesLayer = getOrCreateLinesLayer(map);
		if (routes.length === 0) {
			linesLayer.setSource(new VectorSource());
		}
	}, [map, routes]);

	useEffect(() => {
		document.documentElement.classList.add('prevent-scroll');
		return () => {
			document.documentElement.classList.remove('prevent-scroll');
		};
	});

	useEffect(() => {
		if (map !== null) {
			map.getLayers().forEach(layer => {
				const layerName = layer.get('name');
				if (
					layerName !== undefined &&
					['GIS', 'topkarteMRCV', 'orthoMRCV'].indexOf(layerName) > -1
				) {
					layer.setVisible(mapType === layerName);
				}
			});
		}
	}, [map, mapType]);

	let appClass = 'App';
	if (!routePlanningActive) {
		appClass += ' App--no-route-planning';
	}

	return (
		<Suspense fallback={<></>}>
			<div className={appClass}>
				<LeftSidebar />

				<SettingsButton
					onClick={() => toggleSettingsSidebar(true)}
					pullUp={isLeftSidebarOpen}
				>
					<Tooltip type="textonly" anchor="right" title={t('settings.opener.tooltip')} />
				</SettingsButton>

				<MapModal />
				<SavedModal />

				<Search
					value={(points.length > 1 && getSearchLabelFromResult(points[1])) || ''}
					placeholder={t('common.searchPlaceholder')}
					getInputValueFromResult={getSearchLabelFromResult}
					onSelect={result => {
						if (result.id === routeCache.ROUTE_CACHE_KEY) {
							setPoints(result.points, true);

							if (typeof _paq !== 'undefined') {
								// eslint-disable-next-line no-underscore-dangle
								window._paq.push([
									'trackEvent',
									'Suche', // Category
									'Suchergebnis', // Action
									'Route', // Name
								]);
							}
						} else {
							setPoints([null, result], true);

							if (typeof _paq !== 'undefined') {
								// eslint-disable-next-line no-underscore-dangle
								window._paq.push([
									'trackEvent',
									'Suche', // Category
									'Suchergebnis', // Action
									`${getSearchLabelFromResult(result)} (${result.id})`, // Name
								]);
							}
						}

						if (typeof _paq !== 'undefined') {
							// eslint-disable-next-line no-underscore-dangle
							window._paq.push([
								'trackEvent',
								'Suche', // Category
								'Navigation', // Action
								'Zur Routenplanung', // Name
							]);
						}
					}}
					onClear={() => setPoints([])}
					isHidden={isLeftSidebarOpen}
					resultsFilter={result =>
						result.coord ||
						result.coords ||
						(result.type === 'tip' && result.address.coords)
					}
					zIndex={2}
					showRoute
					subtleBoxShadow
					autoFocus
				/>

				<SettingsSidebar />

				<MapWrapper>
					<svg
						width="0"
						height="0"
						viewBox="0 0 0 0"
						xmlns="http://www.w3.org/2000/svg"
						style={{ position: 'absolute', top: -100, left: -100 }}
					>
						<defs>
							<filter id="active-route">
								{/* Make stroke bigger */}
								<feMorphology
									operator="dilate"
									radius="2"
									in="SourceGraphic"
									result="THICKNESS"
								/>
								{/* Change saturation of thick line */}
								<feColorMatrix
									in="THICKNESS"
									type="saturate"
									values="0.7"
									out="STROKED"
								/>
								{/* Place thick stroked line behind original line */}
								<feComposite
									operator="over"
									in="SourceGraphic"
									in2="STROKED"
									result="comp"
								/>
							</filter>
						</defs>
					</svg>
					<Map
						markers={[
							...sharingMarkers,
							...poiMarkers,
							...stopMarkers,
							...positionMarkers,
							...pointMarkers,
							...(bikeMarker ? [bikeMarker] : []),
							...(locationMarker ? [locationMarker] : []),
						]}
						transportations={mobilityIcons}
						updateMap={newMap => {
							updateUri(newMap);
							setMap(newMap);
						}}
						onPositionChanged={currentMap => {
							updateUri();
							setPosition(currentMap.getView().getCenter());
							tooltip.current.style.display = 'none';
						}}
						allMaps
					/>
				</MapWrapper>

				<Menu isVisible={!isLeftSidebarOpen}>
					<MenuTile
						title={t('page.navigation.toPage')}
						accentColor="red"
						svg={<RadroutenPlanerIcon />}
						img={{
							src: pageRadroutenplaner,
							alt: 'Radroutenplaner',
						}}
						onClick={() => {
							setRoutePlanningActive(true);
							setMap(null);
							history.push('/');

							if (typeof _paq !== 'undefined') {
								// eslint-disable-next-line no-underscore-dangle
								window._paq.push([
									'trackEvent',
									'Menutile', // Category
									'Navigation', // Action
									'Zur Startseite', // Name
								]);
							}
						}}
					/>

					<MenuTile
						title={t('bikeRoutes.headline')}
						accentColor="red"
						icon={IconProps.rundumsrad}
						img={{
							src: radrouten,
							alt: 'Personen beim Radfahren',
						}}
						onClick={() => {
							clearMyTimeout('leftSidebar');
							toggleLeftSidebar(true);
							setRoutePlanningActive(false);
							updateUri(false, `${mapRoot}/radrouten`);

							if (typeof _paq !== 'undefined') {
								// eslint-disable-next-line no-underscore-dangle
								window._paq.push([
									'trackEvent',
									'Menutile', // Category
									'Navigation', // Action
									'Zu den Radtouren', // Name
								]);
							}
						}}
					/>

					{myRoutes.length > 0 && (
						<MenuTile
							title={t('myRoutes.headline')}
							accentColor="red"
							icon={IconProps.places}
							img={{
								src: meineRouten,
								alt: 'Meine Routen',
							}}
							onClick={() => {
								clearMyTimeout('leftSidebar');
								toggleLeftSidebar(true);
								updateUri(false, `${mapRoot}/meine-routen`);
								setRoutePlanningActive(false);

								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'Menutile', // Category
										'Navigation', // Action
										'Zu gespeicherten Routen', // Name
									]);
								}
							}}
						/>
					)}
				</Menu>

				{popup && (
					<Popup
						text={popup.text}
						options={popup.options}
						closePopup={() => openPopup(null)}
					/>
				)}

				{process.env.NODE_ENV === 'development' && shouldDebug && <Debug />}
			</div>
		</Suspense>
	);
}

export default App;
