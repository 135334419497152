import { API_FREIZEITTIPPS_BASE } from './constants';

const accessibilityCache = {};

/**
 * Fetch accessibility data for the passed stops (max. 50)
 *
 * @name fetchAccessibility
 * @function
 * @param {array<StopItem>} stopsList A list of stops to fetch the accessibility data.
 * @return {object} Object with stopId as key and an array with the keys "accessible" (boolean) and "map" (string/url).
 */
export default async (stopsList, fetchOptions = {}) => {
	let stops = '';
	if (stopsList && stopsList.length > 0) {
		stops += '?q=';
		stopsList.every((stop, i) => {
			stops += `${stop},`;
			return i < 50;
		});
		stops = stops.substr(0, stops.length - 1);
	}

	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	if (typeof accessibilityCache[stops] === 'undefined') {
		const response = await fetch(
			`${API_FREIZEITTIPPS_BASE}accessible-stops.json${stops}`,
			fetchOptions,
		);
		accessibilityCache[stops] = await response.json();
	}
	return accessibilityCache[stops];
};
