import React, { useState } from 'react';
import { colors } from 'shared';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import HeaderImgUrl from './assets/images/top.jpg';
import Topic1ImgUrl from './assets/images/topic01.jpg';
import Topic2ImgUrl from './assets/images/topic02.jpg';
import Topic3ImgUrl from './assets/images/topic03.jpg';
import Topic4ImgUrl from './assets/images/topic04.jpg';
import LogoUrl from './assets/icons/logo.svg';
import ArrowUrl from './assets/icons/icon-arrow.svg';

import { CenterPageSection, LeftPageSection, RightPageSection } from './components/PageSection';

const StyledPage = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	max-width: 78rem;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: 640px) {
		grid-template-columns: repeat(16, 1fr);
		row-gap: 3rem;
	}

	@media (min-width: 1024px) {
		row-gap: 6rem;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
`;

const StyledVVSLink = styled.a`
	visibility: hidden;
	display: none;
	align-self: flex-end;
	padding: 1rem;

	@media (min-width: 640px) {
		display: flex;
		visibility: visible;
	}
`;

const StyledHeader = styled.header`
	grid-column: 1 / -1;
	height: 400px;
	display: flex;
	flex-direction: column;
	position: relative;

	@media (min-width: 640px) {
		height: 600px;
	}
`;

const StyledOrangeBar = styled.div`
	width: 100%;
	height: 64px;
	background-color: ${colors.orange};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	color: #fff;

	@media (min-width: 640px) {
		height: 96px;
		a {
			visibility: hidden;
		}
	}
`;

const StyledIcon = styled.div`
	background-image: url(${LogoUrl});
	background-size: cover;
	width: 45px;
	height: 45px;

	justify-self: flex-end;

	@media (min-width: 640px) {
		width: 104px;
		height: 104px;
		transform: translateY(50%);
		z-index: 1;
		margin-right: 52px;
	}
`;

const StyledBanner = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	flex: 1 1 auto;
	background-image: url('${HeaderImgUrl}');
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledHeadline = styled.h2`
	color: white;
	font-size: 24px;
	background-color: ${colors.orange};
	display: inline;
	white-space: nowrap;
	padding: 0.5rem 1rem;

	transform: ${({ left }) => (left ? 'translate(0, 51px)' : 'translate(0, 49px)')};

	@media (min-width: 640px) {
		font-size: 32px;
		align-self: initial;
		transform: ${({ left }) => (left ? 'translate(-5%, 51px)' : 'translate(5%, 49px)')};
	}

	@media (min-width: 1024px) {
		font-size: 48px;
		transform: ${({ left }) => (left ? 'translate(-10%, 101px)' : 'translate(10%, 99px)')};
	}
`;

const StyledShowMore = styled.button`
	display: flex;
	font-weight: bold;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
	color: #097fac;
	padding: 0;

	::after {
		content: '';
		display: block;
		width: 1rem;
		height: 1rem;
		background-image: url(${ArrowUrl});
		background-position: center;
		background-repeat: no-repeat;
		transition: transform 250ms ease-in-out;
		transform: rotate(${({ flipped }) => (flipped ? -180 : 0)}deg);
		margin-left: 0.5rem;
		align-self: center;
	}
`;

const StyledGreenBox = styled.div`
	background-color: #3c8367;
	padding: 1rem;
	display: grid;
	grid-row-gap: 1rem;

	color: #fff;
`;

const StyledFooter = styled.div`
	grid-column: 1 / -1;
	display: flex;
	flex-direction: row;
	padding: 1rem;
	justify-content: space-between;
	a ~ a {
		margin-left: 1rem;
	}
`;

export default function Page() {
	const { t } = useTranslation();
	const [isMoreShown, setShowMore] = useState(false);

	return (
		<StyledPage>
			<StyledHeader>
				<StyledVVSLink href="https://www.vvs.de/home/">
					{t('page.navigation.toVVS')}
				</StyledVVSLink>
				<StyledOrangeBar>
					<a href="https://www.vvs.de/home/">{t('page.navigation.toVVS')}</a>
					<StyledIcon />
				</StyledOrangeBar>
				<StyledBanner role="img" aria-label={t('page.bannerLabel')}>
					<StyledHeadline left>{t('page.banner1')}</StyledHeadline>
					<StyledHeadline>{t('page.banner2')}</StyledHeadline>
				</StyledBanner>
			</StyledHeader>

			<CenterPageSection
				headline={t('page.centerSection.headline')}
				text={t('page.centerSection.text')}
				button={t('page.centerSection.button')}
				link="/karte"
			>
				<StyledShowMore flipped={isMoreShown} onClick={() => setShowMore(v => !v)}>
					{t('page.centerSection.more')}
				</StyledShowMore>

				{isMoreShown && (
					<>
						<StyledGreenBox>
							<strong>{t('page.moreSection.infoBoxHeadline')}</strong>
							<p>{t('page.moreSection.infoBoxList', { joinArrays: '\n\n' })}</p>
						</StyledGreenBox>
					</>
				)}
			</CenterPageSection>


			<LeftPageSection
				headline={t('page.bikeSection.headline')}
				text={t('page.bikeSection.text')}
				button={t('page.bikeSection.button')}
				img={Topic1ImgUrl}
				link="https://www.vvs.de/fahrradmitnahme/"
			/>

			<RightPageSection
				headline={t('page.regioRadPolygoSection.headline')}
				text={t('page.regioRadPolygoSection.text')}
				button={t('page.regioRadPolygoSection.button')}
				img={Topic2ImgUrl}
				link="https://www.vvs.de/rundum-mobil/"
			/>

			<LeftPageSection
				headline={t('page.bikeHikeBusSection.headline')}
				text={t('page.bikeHikeBusSection.text')}
				button={t('page.bikeHikeBusSection.button')}
				img={Topic3ImgUrl}
				link="https://www.orange-seiten.de/freizeitbusse/"
			/>

			<RightPageSection
				headline={t('page.roundlyMobileSection.headline')}
				text={t('page.roundlyMobileSection.text')}
				button={t('page.roundlyMobileSection.button')}
				img={Topic4ImgUrl}
				link="https://www.mypolygo.de/partner/bikesharing/regioradstuttgart/"
			/>

			<StyledFooter>
				<a href="https://www.vvs.de/home/">{t('page.navigation.toVVS')}</a>
				<div>
					<a href="https://www.vvs.de/impressum/">{t('page.navigation.imprint')}</a>
					<a href="https://www.vvs.de/datenschutz/">{t('page.navigation.privacy')}</a>
				</div>
			</StyledFooter>
		</StyledPage>
	);
}
